import _eventListener from "@use-it/event-listener";
import _react from "react";
import _usePersistedState from "use-persisted-state";

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};

function e(e) {
  return e && "object" == typeof e && "default" in e ? e.default : e;
}

var t = e(_eventListener),
    r = _react,
    n = e(_usePersistedState),
    a = function () {},
    u = {
  classList: {
    add: a,
    remove: a
  }
},
    i = function (e, t, a) {
  void 0 === a && (a = _global);
  var i = e ? n(e, t) : r.useState,
      o = a.matchMedia ? a.matchMedia("(prefers-color-scheme: dark)") : {},
      s = {
    addEventListener: function (e, t) {
      return o.addListener && o.addListener(t);
    },
    removeEventListener: function (e, t) {
      return o.removeListener && o.removeListener(t);
    }
  },
      d = "(prefers-color-scheme: dark)" === o.media,
      c = a.document && a.document.body || u;
  return {
    usePersistedDarkModeState: i,
    getDefaultOnChange: function (e, t, r) {
      return void 0 === e && (e = c), void 0 === t && (t = "dark-mode"), void 0 === r && (r = "light-mode"), function (n) {
        e.classList.add(n ? t : r), e.classList.remove(n ? r : t);
      };
    },
    mediaQueryEventTarget: s,
    getInitialValue: function (e) {
      return d ? o.matches : e;
    }
  };
};

exports = function (e, n) {
  void 0 === e && (e = !1), void 0 === n && (n = {});
  var a = n.element,
      u = n.classNameDark,
      o = n.classNameLight,
      s = n.onChange,
      d = n.storageKey;
  void 0 === d && (d = "darkMode");
  var c = n.storageProvider,
      l = n.global,
      f = r.useMemo(function () {
    return i(d, c, l);
  }, [d, c, l]),
      m = f.getDefaultOnChange,
      v = f.mediaQueryEventTarget,
      g = (0, f.usePersistedDarkModeState)((0, f.getInitialValue)(e)),
      h = g[0],
      k = g[1],
      L = r.useMemo(function () {
    return s || m(a, u, o);
  }, [s, a, u, o, m]);
  return r.useEffect(function () {
    L(h);
  }, [L, h]), t("change", function (e) {
    return k(e.matches);
  }, v), {
    value: h,
    enable: r.useCallback(function () {
      return k(!0);
    }, [k]),
    disable: r.useCallback(function () {
      return k(!1);
    }, [k]),
    toggle: r.useCallback(function () {
      return k(function (e) {
        return !e;
      });
    }, [k])
  };
};

export default exports;